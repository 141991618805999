var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"p-2",attrs:{"variant":"light","show":_vm.loading,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('div',{staticClass:"pb-1"},[_c('b-card-actions',{ref:"formCard",attrs:{"title":"Pemutusan Hubungan Kerja","no-actions":"","no-body":""}},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"11"}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"md":"12"}},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-center mr-md-1"},[_c('b-avatar',{attrs:{"size":"86px","src":_vm.imageFile ? _vm.fileUrl(_vm.imageFile) : _vm.imageInitialFile}})],1)]),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":_vm.name,"label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{staticClass:"ml-2",attrs:{"label":"Pilih Jenis","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Pilih Jenis Pemutusan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.pemutusanOptions,"state":errors.length > 0 ? false:null},model:{value:(_vm.fieldPemutusan),callback:function ($$v) {_vm.fieldPemutusan=$$v},expression:"fieldPemutusan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{staticClass:"ml-2",attrs:{"label":"URL Dokumen","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"URL Dokumen","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"url","state":errors.length > 0 ? false:null},model:{value:(_vm.fieldURLDocument),callback:function ($$v) {_vm.fieldURLDocument=$$v},expression:"fieldURLDocument"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{staticClass:"ml-2",attrs:{"label":"URL Lainnya","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"URL Lainnnya","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"url","state":errors.length > 0 ? false:null},model:{value:(_vm.fieldURLDocumentOther),callback:function ($$v) {_vm.fieldURLDocumentOther=$$v},expression:"fieldURLDocumentOther"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"mt-5 ml-2 mb-2 text-right",attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-50",attrs:{"variant":"primary","type":"submit","disabled":_vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }